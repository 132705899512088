.home_flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 4em 2em 1em 2em;
}

.home {
    width: 40vw;
    height: 100%;
    padding: 1em;
    box-sizing: border-box;
}

.home > h1 {
    text-align: center;
}

.home_main {
    text-align: justify;
}

.home_photo {
    margin: 0 auto;
}

.home_photo img {
    width: 15vw;
}

@media  (max-width: 820px) {
    .home_flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
    }

    .home {
        text-align: center;
        margin: 0 auto;
    }

    .home_photo {
        margin: 0 auto;
    }

    img {
        width: 25vw;
    }

}