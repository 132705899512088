.logo {
    width: 100%;
}

.logo img {
    width: 25%;
}

@media  (max-width: 600px) {      
    .logo img {
        width: 35%;
        margin-right: 90%;
    }
}