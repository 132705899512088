* {
    box-sizing: border-box;
}

.projects_flex h1 {
    text-align: center;
}

.projects_boxes div {
    margin: 0 auto;
    width: 15vw;
    height: 20vh;
    border: 1px solid black;
    text-align: center;
}

.projects_boxes img {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.projects_boxes {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, 25em);
    justify-content: center;
    padding: 1em;
    row-gap: 1em;
    column-gap: 1em;
}

.projects_boxes:hover {
    cursor: pointer;
}