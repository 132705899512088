.navbar {
    box-sizing: border-box;
    width: 100%;
    height: 15vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.navbar span {
    margin: 0em 2em 0 2em;
}

.links {
    display: flex;
    text-decoration: none;
    font-size: 18px;
    margin: 2em;
    gap: 1em;
}

.links a {
    text-decoration: none;
    color: black;
}

a:hover {
    color: grey;
}


.menu_burger {
    display: none;
}

@media  (max-width: 700px) {      
    .navbar_logo {
        width: 150%;
        box-sizing: border-box;
    }
    
    .menu_burger {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        right: 10%;
    }

    a {
        text-decoration: none;
        color: black;
    }
    
    .links {
        display: none;
    }
    
    .links:hover{
        font-weight: 700;
    }
    
}